@import 'src/utils/utils';

.request {
  display: flex;
  flex-direction: column;

  .suggestions-wrapper {
    @include Shadow-1;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    margin-top: 10px;
    border-radius: 8px;
    background-color: $Main-2;
    overflow: hidden;

    .suggestions-suggestions div {
      @include Text-16-reg;
      padding-top: 10px;
      padding-right: 16px;
      padding-bottom: 10px;
      padding-left: 16px;
      transition: background-color 0.3s;
      cursor: pointer;

      &:hover {
        @media(min-width: $lg) {
          background-color: $Main-4;
        }
      }

      &:active {
        background-color: $Main-4;
      }
    }

    .suggestions-suggestions div:last-child {
      display: none;
    }

    .suggestions-hint {
      display: none;
    }
  }

  &__header {
    margin-bottom: 20px;
    padding-right: 30px;

    @media(min-width: $md) {
      margin-bottom: 40px;
    }
  }

  &__title {
    @include Head-35-bold;
    margin: 0;
  }

  &__post-title {
    @include Text-16-reg;
    margin: 0;
    margin-top: 10px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__fields {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0;
    padding: 0;
    border: 0;
    gap: 18px;

    @media(min-width: $md) {
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
  }

  &__field {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;

    textarea {
      min-height: 160px;
    }

    &--wide {
      grid-column: 1 / -1;
    }
  }

  &__label {
    @include Text-12-reg;
    margin: 0;
  }

  &__legal {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 18px;
    gap: 15px;
    flex-grow: 1;

    @media(min-width: $md) {
      align-items: flex-start;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 30px;
      gap: 30px;
    }

    .button {
      margin-top: auto;
    }
  }

  &__action {
    display: flex;
    flex-direction: column-reverse;
    gap: 18px;

    @media(min-width: $md) {
      flex-direction: row;
      gap: 20px;
    }

    .button {
      flex-grow: 1;
    }
  }
}